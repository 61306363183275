import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image"

const Text = styled.div`
  margin-bottom: 60px;
  h2 {
    ${fonts.garamondRegular};
    padding-top: 10px;
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 1.21;
  }
  h3 {
    ${fonts.garamondSemiBold};
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    line-height: 1.38;
    margin-bottom: 20px;
    b {
      ${fonts.sofiaProBold};
    }
    i {
      ${fonts.neutraTextBookItalic};
    }
    a {
      color: inherit;
      &.internal {
        background: ${colors.purple};
        text-decoration: none;
      }
    }
    :last-of-type {
      margin-bottom: 0;
    }
  }

  ul {
    li {
      position: relative;
      list-style: disc;
      margin-left: 20px;
      p {
        margin-bottom: 20px !important;
        letter-spacing: normal;
      }

      b {
        ${fonts.garamondSemiBold};
        font-size: 16px;
        line-height: 1.44;
      }
    }
  }

  @media (min-width: ${breakpoints.md}) {
    h2 {
      font-size: 32px;
      margin-bottom: 15px;
      line-height: 1.31;
    }
    ul {
      margin-left: 23px;
      li {
        b {
          font-size: 18px;
          line-height: 1.5;
        }
        ::before {
          left: 21px;
        }
      }
    }
  }
`

const Intro = styled.div`
  p {
    b {
      ${fonts.sofiaProBold};
      font-size: 16px;
      line-height: 1.44;
    }
    :last-of-type {
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    p b {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: normal;
    }
  }
`

const ImageContainer = styled.div`
  margin-bottom: 20px;
`

const websiteUrl = process.env.WEBSITE_URL
const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        node.intro ?
        <Intro><p>{children}</p></Intro>
        : 
        <p>{children}</p>
      )
    },
    [INLINES.HYPERLINK]: (node, next) => {
      const uri = node.data.uri
      const isInternal =
        uri.includes(`${websiteUrl}/quiz`) ||
        uri.includes(`${websiteUrl}/products`) ||
        uri.includes(`${websiteUrl}/sets`)
      return isInternal ? (
        <a href={uri} className="internal">
          {next}
        </a>
      ) : (
        <a href={uri} target="_blank" rel="noreferrer" aria-label={next}>
          {next}
        </a>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      let asset = node.data?.target
      const { file, gatsbyImageData, title } = asset

      return gatsbyImageData ? (
        <ImageContainer>
          <GatsbyImage image={gatsbyImageData} alt={title || ""} />
        </ImageContainer>
      ) : file?.url ? (
        <ImageContainer>
          <img src={file.url} alt={title || ""} />
        </ImageContainer>
      ) : null
    },
  },
}

const TextBlock = (props) => {
  const { wysiwyg } = props

  let parsedWysiwygRaw = JSON.parse(wysiwyg.raw)

  // Every paragraph before the first heading is part of the intro
  for (let i = 0; i < parsedWysiwygRaw.content.length; i++) {
    if (parsedWysiwygRaw.content[i].nodeType === "paragraph") {
      parsedWysiwygRaw.content[i].intro = true
    }
    if (parsedWysiwygRaw.content[i].nodeType === "paragraph" && parsedWysiwygRaw.content[i + 1] && parsedWysiwygRaw.content[i + 1].nodeType === "heading-2") {
      break
    }
  }

  parsedWysiwygRaw = JSON.stringify(parsedWysiwygRaw)

  wysiwyg.raw = parsedWysiwygRaw

  return <Text>{renderRichText(wysiwyg, options)}</Text>
}

export default TextBlock
